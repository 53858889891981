import React from "react"
import Index from "../components/layout"

import CollectionSelector from "../components/collectionSelector/collectionSelector";
import scrollTo from "gatsby-plugin-smoothscroll";

import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const title = 'Collections for sale';
const description = "Looking for specimens?  You are in the right place then. Buy minerals from the stock on ebay and eRocks";


const SelectionsForSale = () => {

    const TRANSITION_LENGTH = .5

    const exitTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPage(),
    }

    const exitReverseTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageReverse(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPage() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateExitPageReverse() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '-60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <Index title={title} description={description}>
            <CollectionSelector exitTransition={exitTransition} entryTransition={entryTransition} />
        </Index>
    )
}

export default SelectionsForSale
