import React, {useEffect, useRef} from 'react';
import Img from "gatsby-image";
import {Power3, TweenLite} from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";

const Collection = ({ collection, exitTransition, entryTransition }) => {

    let collectionSelectorItem = useRef(null);
    let collectionSelectorImage = useRef(null);
    let collectionSelectorTitle = useRef(null);
    let collectionSelectorText = useRef(null);
    let collectionSelectorLink = useRef(null);

    useEffect(() => {

        TweenLite.from( collectionSelectorImage, 1.4,{
            scale: .2,
            opacity: 0,
            ease: Power3.easeOut,
        })

        TweenLite.from( collectionSelectorTitle, 1, {
            y: 40,
            opacity: 0,
            ease: Power3.easeOut,
        })

        TweenLite.from( collectionSelectorText, 1, {
            y: 40,
            opacity: 0,
            ease: Power3.easeOut,
            delay: .2,
        })

        TweenLite.from( collectionSelectorLink, 1, {
            y: 40,
            opacity: 0,
            ease: Power3.easeOut,
            delay: .4,
        })

    },[collectionSelectorImage, collectionSelectorTitle, collectionSelectorText, collectionSelectorLink ])

    return (

        <div ref={el => (collectionSelectorItem= el)} className="single-collection">
            <div ref={el => (collectionSelectorImage = el)} className="global-gatsby-image-wrapper">
                <Img className="main-img"
                     fluid={collection.node.icon.childImageSharp.fluid}
                     alt={collection.node.name}
                />
            </div>
            <h2 ref={el => (collectionSelectorTitle = el)} >{collection.node.name}</h2>
            <p ref={el => (collectionSelectorText = el)} >{collection.node.description}</p>
            <div ref={el => (collectionSelectorLink = el)} >
                <TransitionLink
                    className="btn-cta"
                    to={"/minerals/" + collection.node.slug}
                    exit={exitTransition}
                    entry={entryTransition}
                >
                    Get the Rocks
                </TransitionLink>
            </div>
        </div>
    )
}

export default Collection
