import React from "react"
import {graphql, StaticQuery} from "gatsby";

import Collection from "./collection";


const CollectionSelector = ({ exitTransition, entryTransition }) => {

    return (
        <StaticQuery
            query={graphql`
                query {
                  collectionsForSale: allStrapiCategory(filter: {forSale: {eq: true}}) {
                    edges {
                    node {
                      name
                      page_title
                      description
                      slug
                      icon {
                        childImageSharp {
                          fluid {
                             ...GatsbyImageSharpFluid_withWebp_tracedSVG
                          }
                        }
                      }
                    }
                    }
                  }
                }
            `}

            render={data => (
                <section className="regular-section collection-select">
                    {data.collectionsForSale.edges.map((collection, i) => {
                        return (
                            <Collection collection={collection} exitTransition={exitTransition} entryTransition={entryTransition}/>
                        )
                    })}
                </section>
            )}
        />
    );
}

export default CollectionSelector

